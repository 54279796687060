import { useState, useEffect, ChangeEvent } from 'react';

function LanguageSelector() {
  // Step 1: Initialize state for currency
  const [language, setLanguage] = useState('en_EN'); // Default to USD

  // Step 2: Retrieve the currency value from localStorage if available
  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []); // Empty dependency array to run this effect only once, during component initialization

  // Function to handle currency change
  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;

    // Update state and localStorage
    setLanguage(newLanguage);
    localStorage.setItem('pxlLanguage', newLanguage);
    
    const currentPath = window.location.pathname + window.location.search;
const newPath = currentPath.replace(/^\/(en|fr)(\/|$)/, `/${newLanguage}$2`);

window.location.href = window.location.origin + newPath;


    const metastarkAddress = localStorage.getItem('walletAddress');
    const metastarkStarkey = localStorage.getItem('walletStark');
    const metastarkNetwork = localStorage.getItem('walletNetwork');
    const metastarkEmail = localStorage.getItem('walletEmail');
    const metastarkProvider = localStorage.getItem('walletPreference');
    const metaCurrency = localStorage.getItem('pxlCurrency');
    const metaBalance = localStorage.getItem('pxlBalance');
    const metaLanguage = localStorage.getItem('pxlLanguage');

    window.postMessage(
      {
        type: 'UPDATE_WALLET_ADDRESS',
        walletAddress: metastarkAddress,
        walletNetwork: metastarkNetwork,
        walletStark: metastarkStarkey,
        walletEmail: metastarkEmail || '',
        walletPreference: metastarkProvider,
        pxlCurrency: metaCurrency,
        pxlLanguage: metaLanguage,
      },
      window.location.origin // Only send the message to the same origin
    );
  };

  return (
    <span>
      <select value={language} onChange={handleLanguageChange} className="currency-selector">
        <option value="en">🇬🇧 English</option>
        <option value="fr">🇫🇷 Français</option>
      </select>
    </span>
  );
}

export default LanguageSelector;












